import type { ReactElement } from 'react'

import Link from '../../templateComponents/Link'

type BreadcrumbProps = {
  breadcrumb: {
    href: string
    title: string
  }[]
}

export function HiddenBreadcrumb({ breadcrumb }: Readonly<BreadcrumbProps>): ReactElement {
  return (
    <span itemScope itemType="https://schema.org/BreadcrumbLis>t">
      {breadcrumb.map((page, index) => (
        <span itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" key={page.href}>
          <link href={page.href} itemProp="item" />
          <meta itemProp="name" content={page.title} />
          <meta itemProp="position" content={`${index + 1}`} />
        </span>
      ))}
    </span>
  )
}

export function VisibleBreadcrumb({ breadcrumb }: Readonly<BreadcrumbProps>): ReactElement {
  return (
    <ul itemScope itemType="https://schema.org/BreadcrumbList" className="breadcrumb">
      {breadcrumb.map((page, index) => (
        <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" key={page.href}>
          <Link to={page.href} itemProp="item">
            <span itemProp="name">{page.title}</span>
          </Link>
          <meta itemProp="position" content={`${index + 1}`} />
        </li>
      ))}
    </ul>
  )
}

export default function Breadcrumb({
  breadcrumb,
  isHidden = false,
}: Readonly<
  BreadcrumbProps & {
    isHidden?: boolean
  }
>) {
  if (breadcrumb.length <= 1) return null

  return isHidden ? <HiddenBreadcrumb breadcrumb={breadcrumb} /> : <VisibleBreadcrumb breadcrumb={breadcrumb} />
}
