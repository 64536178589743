import * as plugins from './plugins'
import buildURL from '../../../../utils/buildURL'
import checkSupportedImageMediaType from '../../../utils/checkSupportedImageMediaType'

export const allowedMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']
export const isValidMimeType = (mimeType) => allowedMimeTypes.indexOf(mimeType) >= 0
export const mimeTypeMap = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
}

export function storeFile(api) {
  return async function (file, config) {
    const isSupportedImageMediaType = Boolean(await checkSupportedImageMediaType(file))

    const response = await api.post('/storage/images/' + encodeURI(file.name), file, {
      // make the storage service return HTTP 415 instead of 400 so our statusToErrorCode works as expected
      headers: { 'Content-Type': isSupportedImageMediaType ? file.type : '' },
      ...config,
    })
    const data = await response.data
    const storedFile = await Object.assign(data, {
      absoluteDownloadUrl: `/storage/images/${data.relativeDownloadUrl}`,
    })

    return storedFile
  }
}

export const imageUrl = (shopSlug) => (url) => buildURL(url, { shop: shopSlug })
export const resizedUrl = (width, height) => (url) => buildURL(url, { width, height })

export default function daliConfig(isEditor, api, shopSlug) {
  return {
    plugins: {
      'epages.text': { component: plugins.TextPlugin },
      'epages.image': {
        component: plugins.ImagePlugin,
        config: { storeFile: storeFile(api), imageUrl: imageUrl(shopSlug), previewUrl: resizedUrl(680) },
      },
      'epages.icon': {
        component: plugins.IconPlugin,
      },
      'epages.contentslider': {
        component: plugins.ContentSliderPlugin,
        config: { storeFile: storeFile(api), imageUrl: imageUrl(shopSlug), previewUrl: resizedUrl(680) },
      },
      'epages.productslider': {
        component: plugins.ProductSliderPlugin,
        config: {
          thumbnailUrl: resizedUrl(120, 120),
        },
      },
      'epages.video': {
        component: plugins.VideoPlugin,
      },
      'epages.product': {
        component: plugins.ProductPlugin,
        config: {
          thumbnailUrl: resizedUrl(120, 120),
        },
      },
      'ng.category': {
        component: plugins.CategoryPlugin,
        config: {
          imageUrl: (url) => url,
        },
      },
      'epages.gmaps': {
        component: plugins.GoogleMapsPlugin,
        config: {},
      },
      'epages.html': {
        component: plugins.FreeHtmlPlugin,
        config: {},
      },
      'epages.contactform': {
        component: plugins.ContactFormPlugin,
        config: {},
      },
      'epages.divider': {
        component: plugins.DividerPlugin,
      },
      'epages.expander': {
        component: plugins.ExpanderPlugin,
      },
      'epages.imagegallery': {
        component: plugins.ImageGalleryPlugin,
        config: { storeFile: storeFile(api), imageUrl: imageUrl(shopSlug), previewUrl: resizedUrl(300) },
      },
    },
  }
}
