import type { AxiosError } from 'axios'

import { mimeTypeMap } from '../templateComponents/Workspace/daliConfig'

export async function getFileFromCanvas(canvas: HTMLCanvasElement, file: File) {
  // If the source file is a GIF image, get the file from the canvas as a PNG
  // image. The canvas will only have one frame of the GIF image and the old
  // "epages-storage" storage service would throw a 400 Bad Request error on
  // upload ("Image format does not match media type image/gif").
  const mediaType = file.type === 'image/gif' ? 'image/png' : file.type
  const filename = file.type === 'image/gif' ? file.name.replace(/\.gif$/, '.png') : file.name
  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob((blob) => resolve(blob), mediaType)
  })
  return new File([blob!], filename, { type: mediaType })
}

export function getFilename(src: string) {
  const url = new URL(src, window.location.origin)
  let filename = url.searchParams.has('remote')
    ? url.searchParams.get('remote')!.split('/').pop()!
    : decodeURIComponent(url.pathname.split('/').pop()!)
  try {
    // Decode double encoded source
    filename = decodeURIComponent(filename)
  } catch {}
  return filename
}

export async function loadFile(src: string) {
  const response = await fetch(src)
  const blob = await response.blob()
  return new File([blob], getFilename(src), { type: response.headers.get('Content-Type')! })
}

export function getNormalizedFile(file: File, mediaType: string) {
  const ext = Object.keys(mimeTypeMap).find((ext) => mimeTypeMap[ext] === mediaType)
  const extStartPos = file.name.lastIndexOf('.')
  const filename = file.name.substring(0, extStartPos < 0 ? file.name.length : extStartPos) + `.${ext}`
  return new File([file], filename, { type: mediaType })
}

export function getRelativeDelta(delta: number, baseSize: number, baseClientSize: number) {
  const base = (1 / baseSize) * baseClientSize
  return delta / base
}

export function getNormalizedZoomFactor(zoomFactor: number) {
  // Round to one decimal place to avoid floating point errors
  return parseFloat(zoomFactor.toFixed(1))
}

const errorReasonKeys = ['fileSizeTooLarge', 'unsupportedMediaType', 'unknown'] as const
type ErrorReasonKey = (typeof errorReasonKeys)[number]
export function getErrorReasonKey(exception: unknown): ErrorReasonKey {
  const { response } = exception as AxiosError
  switch (response?.status) {
    case 413:
      return 'fileSizeTooLarge'
    case 415:
      return 'unsupportedMediaType'
    default: {
      const hasErrorReasonKey =
        exception instanceof Error && errorReasonKeys.includes(exception.message as ErrorReasonKey)
      return hasErrorReasonKey ? (exception.message as ErrorReasonKey) : 'unknown'
    }
  }
}
