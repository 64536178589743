export function extractYoutubeId(url: string): string | undefined {
  const regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]+).*/
  const match = regex.exec(url)

  return match !== null ? match[1] : undefined
}

export function extractVimeoId(url: string): string | undefined {
  const regex =
    /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\\-]+)?/i
  const match = regex.exec(url)

  return match !== null ? match[1] : undefined
}
